/*globals $*/
'use strict';

const getValidationFunctions = () => import("./validate");

document.addEventListener('DOMContentLoaded', function () {
  let elements = document.getElementsByClassName('show-popup');

  for (let i = 0; i < elements.length; i++) {
    elements[i].addEventListener('click', function () {
      document.getElementById('popup').style.display = 'block';
    });
  }


  var mobileMenuBtn = document.getElementById('mobile__menu-btn');

// If the element exists, add an event listener
  if (mobileMenuBtn !== null) {
    mobileMenuBtn.addEventListener('click', function () {
      toggleMobileMenu();
    });
  }

  const cards = document.querySelectorAll('.card');
  const cards_coverage = document.querySelectorAll('.card_coverage');
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('visible');
      }
    });
  }, {threshold: 0.5});


  cards.forEach(card => {
    observer.observe(card);
  });

  cards_coverage.forEach(card => {
    observer.observe(card);
  });


  const counters = document.querySelectorAll('.count');

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
  };

  const observerkpi = new IntersectionObserver(function (entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const target = +entry.target.getAttribute('data-target');
        const increment = target / 100;

        const updateCounter = () => {
          const value = +entry.target.innerText;
          if (value < target) {
            entry.target.innerText = Math.ceil(value + increment);
            setTimeout(updateCounter, 10);
          } else {
            entry.target.innerText = target;
          }
        };

        updateCounter();
        observer.unobserve(entry.target);
      }
    });
  }, options);

  counters.forEach(counter => {
    observerkpi.observe(counter);
  });

  const titles = document.querySelectorAll(".elegir-bullet-title");
  titles.forEach((title) => {
    title.addEventListener("click", function () {
      const content = this.nextElementSibling;
      const arrow = this.querySelector('.arrow');
      if (content.classList.contains("show")) {
        content.classList.remove("show");
        arrow.classList.remove("rotate-up");
      } else {
        // Close other open items
        titles.forEach((otherTitle) => {
          const otherContent = otherTitle.nextElementSibling;
          const otherArrow = otherTitle.querySelector('.arrow');

          if (otherContent !== content && otherContent.classList.contains(
              "show")) {
            otherContent.classList.remove("show");
            otherArrow.classList.remove("rotate-up");
          }
        });

        content.classList.add("show");
        arrow.classList.add("rotate-up");
      }
    });
  });

  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop
        > 50) {
      document.querySelector("nav").classList.add("fixed-header");
      //document.querySelector(".add-white-color").classList.add("color-white");
    } else {
      document.querySelector("nav").classList.remove("fixed-header");
      //document.querySelector(".add-white-color").classList.remove(
        //  "color-white");
    }
  }

  function toggleMobileMenu() {
    var navLinks = document.querySelector('.nav-links');
    navLinks.classList.toggle('active');
  }
});
